@import "styles/mixins/index";

.promotionContent {
  @include flex($direction: column, $align: flex-start, $justify: center);

  width: 100%;
  padding: 8px 0;
  color: $color-ui-2;
}

.promotionsSection:not(:last-child) {
  border-bottom: 2px solid $color-ui-5;
}

.promotionWrapper {
  width: 100%;
  padding: 0 0 8px;
  border-top: 1px solid $color-ui-5;
}

.promotionHeader {
  padding: 10px 0 10px 8px;
  background-color: $color-ui-7;
}

.promotionName {
  font-size: 18px;
  font-weight: bold;
}

.promotionDesc {
  font-size: 13px;
}

.ownMaterials {
  padding: 2px 8px;
}

.competitionMaterials {
  padding: 0 8px;
}

.ownCompanyName {
  font-weight: bold;
  font-size: 17px;
  padding: 10px 0 0;
}

.competitionHeader {
  font-weight: bold;
  padding: 10px 0 0;
  font-size: 17px;
}

.competitionCompanyName {
  display: block;
  font-weight: bold;
  color: $color-ui-2;
  font-size: 13px;
}

.productNames {
  font-size: 13px;
  color: $color-ui-15;
  padding: 2px 8px 2px 4px;
}

.noPaddingTop {
  padding-top: 0;
}
