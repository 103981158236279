@import "styles/index";

$arrow-size: 12px;
$separator-size: 1px;

.arrow {
  width: $arrow-size;
  height: $arrow-size;

  &.neutral {
    color: $color-ui-10;
    transform: rotate(-90deg);
  }

  &.positive {
    color: $color-ui-9;
  }

  &.negative {
    color: $color-ui-11;
  }
}

.container {
  margin-right: 64px;
  display: flex;
  flex-direction: column;
}

.labelWrapper {
  display: flex;
  column-gap: 4px;
}

.tooltip {
  @include defaultTooltip(attr(data-tooltip));

  position: relative;

  & svg {
    width: 16px;
    height: 16px;
    transform: translateY(-1px);
  }

  &:hover::after {
    box-sizing: border-box;
    width: 180px;
    top: unset;
    bottom: calc(100% + 5px);
  }
}

.label {
  font-size: 14px;
  color: $color-ui-14;
  white-space: pre-line;
}

.baseWrapper {
  margin: 16px 0 28px;
}

.base {
  font-size: 40px;
  color: $color-ui-2;
  font-weight: bold;
}

.baseSecondary {
  font-size: 17px;
  color: $color-ui-2;
  font-weight: bold;
}

.details {
  display: grid;
  grid-template-columns: $arrow-size max-content $separator-size max-content;
  align-items: center;
  column-gap: 8px;
  margin-top: auto;
}

.detail {
  font-size: 16px;
  color: $color-ui-14;
}

.separator {
  display: block;
  width: $separator-size;
  height: 16px;
  background-color: $color-ui-32;
}
