@import "styles/modules/constants";
@import "styles/mixins";

.selectWrapper {
  width: 100%;
  position: relative;
  height: auto;
}

.selectMenuButton {
  @include flex($justify: space-between);

  border: 1px solid $color-ui-16;
  border-radius: 20px;
  width: 100%;
  padding: 8px 22px;
  text-align: left;
  max-height: 35px;
  font-size: 15px;
  color: $color-ui-2;
}

.menuWrapper {
  @include flex($direction: column);
  @include position(absolute, $top: calc(100% + 5px), $left: 0);

  background-color: $color-ui-13;
  border: 1px solid $color-ui-16;
  color: $color-ui-2;
  font-size: 15px;
  width: 100%;
  border-radius: 10px;
  overflow: auto;
  max-height: 200px;
  z-index: 10;
}

.selectOption {
  padding: 8px 22px;
  text-align: left;
  min-width: 100%;
  cursor: pointer;
  border-bottom: 1px solid $color-ui-16;
  background-color: $color-ui-13;
  transition: all 0.2s ease-in;

  &:hover {
    background-color: $color-ui-32;
  }
}

.labelText {
  font-weight: 600;
  padding-bottom: 8px;
}

.chevronIcon {
  polygon {
    fill: $color-ui-2;
  }
}

.errorText {
  padding-left: 22px;
  padding-top: 4px;
  font-size: 14px;
  font-weight: 600;
  color: $color-ui-11;
}

.placeholder {
  color: $color-ui-3;
  font-size: 15px;
}
