@import "styles/index";

.container {
  @include flex($wrap: wrap);

  width: calc(100% + 80px);
  padding: 0 40px 10px;
  margin: 0 -40px;
  gap: 12px;
}

.lineWrapper {
  @include flex;
  @include p(1, 2);

  border-radius: 5px;
  transition: background-color 0.2s ease;

  &.active {
    background-color: $color-ui-26;
  }
}

.lineLabel {
  @include ml(2);

  font-size: 14px;
  color: $color-ui-3;
}
