@import "styles/index";

.cardHeader {
  @include flex;

  padding: 0;
}

.blockedItem {
  @include defaultTooltip(attr(data-tooltip));

  position: relative;
}

.section {
  width: 100%;
  min-height: 660px;
}

.chartSectionWrapper {
  @include flex($justify: space-between, $wrap: nowrap);

  margin-top: 40px;
}

.dashboardSection,
.dashboardCard {
  display: flex;
  flex: 1;
}

.chart {
  @include flex($justify: center, $direction: column);
  @include m(1, 0, 3);

  position: relative;
  min-height: 280px;
  cursor: pointer;

  svg {
    overflow: visible;
  }
}

.axisLabel {
  color: $color-ui-2;
  font-size: 14px;
  font-weight: 600;
  text-align: center;

  ::first-letter {
    text-transform: capitalize;
  }
}

.chartLoader.chartLoader {
  @include loaderBlock;

  margin: 0;
}

.relative {
  position: relative;
}

.chartTopOptions {
  @include flex($align: center);

  flex-wrap: wrap;
}

.chartSectionDatesLabel {
  display: block;
  margin: 0 auto;
  font-weight: bold;
  color: $color-ui-39;
  font-size: 17px;
  width: fit-content;
  padding-top: 15px;
}

.modalText {
  @include my(2);

  strong {
    font-weight: 600;
  }
}

.modalList {
  @include ml(3);

  list-style-type: disc;
}

.modalLink {
  color: $color-ui-9;

  &:hover {
    text-decoration: none;
  }
}

.modalImage {
  display: block;
  width: 100%;
  height: auto;
}
