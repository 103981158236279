@import "styles/modules/constants";
@import "styles/mixins/index";

.base {
  @include flex;
}

.chartContainer {
  flex: 1 1 70%;
  text-align: center;
}

.legendContainer {
  @include flex;

  flex: 1 1 30%;
  justify-content: center;
  position: relative;
}
