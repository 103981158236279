@import "styles/mixins/index";

.base {
  @include resetButton;
  @include globalInput($color-ui-9);

  border: 1px solid $color-ui-2;
  min-width: 112px;
  min-height: 32px;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &[disabled] {
    @include inputDisabled;
  }

  padding: 0 20px;
}
