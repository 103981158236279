@import "styles/index";
@import "styles/mixins";

.table {
  display: inline-block;
  border-spacing: 0;
  border-radius: 14px;
}

.tableHeaderRow {
  position: relative;
  background-color: $color-ui-6;

  &:first-child {
    border-radius: 14px 14px 0 0;
  }
}

.tableRow {
  border-bottom: 1px solid $color-ui-21;
  background-color: $color-ui-7;
}

.tableRowListWrapper {
  &:last-child {
    .tableRow {
      border-bottom: 0;
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
    }
  }
}

.even {
  background-color: $color-ui-13;
}

.tableBody {
  border: 1px solid $color-ui-21;
  border-radius: 0 0 14px 14px;
  position: relative;
}

.tableHeaderCell {
  text-align: left;
  padding: 24px 20px 16px 16px;

  &:first-child {
    padding-left: 32px;
  }

  &:last-child {
    padding-left: 0;
    padding-right: 32px;
  }
}

.tableCell {
  @include flex($align: center);

  text-align: left;
  padding: 0 20px 0 16px;

  &:first-child {
    padding-left: 32px;
  }

  &:last-child {
    padding-left: 0;
    padding-right: 32px;
  }
}

.listWrapper {
  box-sizing: content-box;
  overflow: hidden !important;
}

.totalSegmentRow {
  background-color: $color-ui-9;
  position: relative;

  p {
    color: $color-ui-13;
  }
}

.segmentRow {
  font-style: italic;
}
