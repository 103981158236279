@import "styles/index";

.segmentsLegend {
  position: fixed;
  bottom: 0;
  height: auto;
  max-height: 300px;
  transition: max-height 0.3s ease-in-out;
  background: $color-ui-13;
  border: 1px solid $color-ui-17;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.segmentsTabs {
  :global(.react-tabs) {
    @include flex($direction: column);
  }

  padding-bottom: 20px;
}

.segmentsTabItem {
  padding-left: 35px;
}

.chartsLabel {
  margin-left: 40px;
  color: $color-ui-2;
}

.chartsList {
  display: block;
  padding: 8px 40px 0;
}

.chartsItem {
  display: inline;
  margin-right: 8px;
  color: $color-ui-15;
  cursor: pointer;
}

.legendBoxClosed {
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

.button {
  @include buttonPrimary;
  @include sidebarToggleButton;
  @include position(absolute, $top: -32px, $left: 95%);
  @include halfButton(right);

  transform: rotate(270deg);
  padding: 0;

  & svg {
    @include sidebarToggleButtonIcon;
  }
}

.buttonOpen {
  @include buttonSecondary;
}

.tabPanelSegmentsList {
  flex-grow: 1;
}

.tabPanelSegmentsListItem {
  display: inline-block;
  margin-right: 8px;
  color: $color-ui-34;
  cursor: pointer;
}

.tabPanelChartsNames {
  width: 100%;
  list-style-type: "none";

  &::marker {
    content: "";
  }
}
