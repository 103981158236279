@import "styles/index";

.multiselect {
  display: flex;
  align-items: center;

  .label {
    color: $color-ui-3;
    font-size: 15px;
    margin-right: 8px;
  }

  .inputWrapper {
    position: relative;
  }

  .input {
    cursor: default;
    padding: 1px 4px;
    color: $color-ui-2;
    border: 1px solid $color-ui-5;
    border-radius: 3px;
    text-overflow: ellipsis;
  }

  .content {
    position: absolute;
    bottom: calc(100% + 4px);
    z-index: 6;
    min-width: 16rem;
    max-height: 24rem;
    overflow-y: auto;
    background-color: $color-ui-13;
    border: 1px solid $color-ui-16;
    border-radius: 10px;
    box-shadow: $defaultBoxShadow;
  }

  .contentHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    padding: 8px 12px;
    border-bottom: 1px solid $color-ui-16;
    background-color: $color-ui-13;
    z-index: 7;
  }

  .headerSummary {
    color: $color-ui-14;
    font-size: 12px;
  }

  .headerButton {
    cursor: pointer;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: $color-ui-3;
    font-size: 14px;
    font-weight: 600;
  }

  .contentBody {
    padding: 12px;
  }

  .checkboxBase:not(:last-child) {
    margin-bottom: 4px;
  }

  .checkboxInput {
    margin-right: 8px;
  }

  .checkboxLabel {
    color: $color-ui-2;
    font-size: 15px;
  }
}
