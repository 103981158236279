@import "styles/modules/constants";

.line {
  fill: none;
  stroke: steelblue;
  stroke-width: 2px;
}

.grid line {
  stroke: $color-chart-7;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
  stroke-width: 1;
  fill: transparent;
  fill-rule: evenodd;
  stroke-dasharray: 2;
  stroke-linecap: square;
}

.grid path {
  stroke-width: 0;
}

.zeroLine {
  line {
    stroke-width: 1.5;
    stroke: $color-ui-5;
    stroke-dasharray: 1;
  }
}
