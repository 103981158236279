@import "styles/index";

.overlay {
  padding: 20px;
}

.container {
  @include flex($direction: column);

  width: 100%;
  max-width: 660px;
  margin: 0 auto;
  padding: 42px 48px;
  border-radius: 14px;
  background-color: $color-ui-13;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: $color-ui-1;
}

.copy {
  margin: 12px 0 24px;
  color: $color-ui-2;
  line-height: 1.3;
}

.button {
  @include buttonPrimary;
  @include buttonTextPrimary;

  width: fit-content;

  &.center {
    align-self: center;
  }
}
