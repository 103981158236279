@import "styles/modules/reset";
@import "styles/modules/constants";

.base,
.base:visited {
  cursor: pointer;
  text-decoration: none;
}

.active {
  pointer-events: none;
}
