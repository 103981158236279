@import "styles/index";

.historicalSection {
  max-width: 680px;
}

.checkbox {
  align-self: flex-start;
}

.options {
  @include flex($align: center);

  margin-top: 14px;
}

.optionsTooltip {
  @include defaultTooltip("Skorzystaj z opcji, aby wybrać ilość dni przed pierwszym dniem zaznaczonego okresu bazowego. Możesz także wyrównać dni tygodnia między okresami.");

  position: relative;

  &:hover::after {
    top: 50%;
    left: calc(100% + 5px);
    transform: translateY(-50%);
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.optionsLabel {
  font-size: 15px;
  font-weight: 600;
  color: $color-ui-2;
  margin-left: 6px;
}

.optionButton {
  @include p(1, 2);

  cursor: pointer;
  font-size: 14px;
  color: $color-ui-3;
  background-color: $color-ui-13;
  border: 1px solid $color-ui-29;
  border-radius: 16px;
  margin-left: 6px;

  &:hover {
    background-color: $color-ui-7;
  }
}

.inputs {
  display: grid;
  grid-template-columns: 180px 180px 1fr;
  grid-gap: 14px;
  align-items: center;
  margin-top: 14px;
}

.leapYearCheckbox {
  @include mt(3);

  align-self: flex-start;
}

.info {
  width: max-content;
}

.historicalSectionMonth {
  .inputs {
    grid-template-columns: repeat(2, 1fr);
  }

  .info {
    grid-column: 1/-1;
  }
}
