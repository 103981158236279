@import "styles/modules/constants";
@import "styles/mixins";

.cookieConsent {
  @include position($position: fixed, $bottom: 0, $left: 0);

  background-color: $color-ui-13;
  border-top: 6px solid $color-ui-9;
  display: block;
  width: 100%;
  z-index: 6;
}

.wrapper {
  box-sizing: border-box;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
  margin: 0 auto;
  max-width: 1310px;
  padding: 24px;

  @media (min-width: $bp-sm) {
    padding: 32px;
  }
}

.content {
  overflow-y: hidden;
  grid-column: 1/-1;

  @media (min-width: $bp-md) {
    grid-column: 1/4;
  }
}

.details {
  font-size: 16px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

.link {
  color: $color-ui-9;

  &:hover {
    text-decoration: none;
  }
}

.buttons {
  grid-column: 1/-1;

  @media (min-width: $bp-md) {
    grid-column: 4/-1;
  }
}

.acceptButton {
  @include buttonPrimary;
  @include buttonTextPrimary;

  margin-bottom: 10px;
  width: 100%;
}

.declineButton {
  @include buttonTextSecondary;

  border: 0;
  font-size: 11px;
  font-weight: normal;
  width: 100%;
}

.cookieItem {
  font-size: 16px;
  margin-bottom: 15px;
}

.cookieClickableArea {
  @include inline-flex($align: center);

  margin-bottom: 5px;
  cursor: pointer;
}

.cookieLabel {
  font-weight: 600;
  transform: translateY(1px);
}
