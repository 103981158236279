@import "styles/index";

.base {
  margin-top: 40px;
}

.heading {
  @include flex($align: center, $justify: space-between);

  margin-bottom: 32px;
}

.periodMenuWrapper {
  @include flex($align: center, $justify: flex-end);
}

.tablePeriodMenu {
  @include mr(3);
}

.smallText {
  font-size: 14px;
}
