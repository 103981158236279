@import "styles/modules/constants";
@import "styles/mixins/index";

$border: 1px solid $color-ui-16;

.base {
  background-color: $color-ui-13;
  width: max-content;
  min-width: 320px;
  max-width: calc(100vw - 40px);
  overflow: hidden;
  box-shadow: 0 4px 16px 2px rgba(56, 69, 93, 0.14);
  border: $border;
  border-radius: 10px;
}

.input {
  padding-right: 20px;
  width: 100%;
}

.label {
  margin-bottom: 18px;
  color: $color-ui-1;
  padding: 0 16px;
  text-align: center;
}

.list {
  list-style-type: none;
}

.item {
  position: relative;
  min-height: 32px;
  padding: 8px 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 50px;
  padding-right: 18px;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: darken($color-ui-7, 10%);
  }
}

.iconContainer {
  position: absolute;
  left: 25px;
}

.itemsCounter {
  position: relative;
  min-height: 32px;
  min-width: 370px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  transition: background-color 0.1s ease-in-out;
  border-bottom: 1px solid $color-ui-16;
}

.itemsCounterText {
  font-size: 12px;
  color: $color-ui-14;
}

.itemsCounterButton {
  @include resetButton;

  margin: 0 3px;
  outline: none;
  display: block;
  cursor: pointer;
}

.itemsCounterButtonText {
  @include flex($align: center);

  font-weight: 600;
  color: $color-ui-3;
  font-size: 14px;
}

.buttonsSplitter {
  margin-bottom: 3px;
}

.dropdownAlert {
  background-color: $color-ui-18;
  padding: 12px 20px;
  color: $color-ui-13;
  max-width: 370px;
  border-radius: 10px;
}
