@import "styles/modules/constants";
@import "styles/mixins";

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
}

.inlinePlaceholder {
  @include flex(rowm center, space-between);

  padding: 8px 16px 0;
  position: relative;
  min-height: 32px;
  transition: background-color 0.1s ease-in-out;
  border-bottom: 1px solid $color-ui-16;
  font-size: 14px;
}

.filterDropdownProductMenu {
  min-width: 450px;
}
