@import "styles/modules/constants";

.outerBar {
  width: 50%;
  height: 6px;
  background: $color-ui-3;
  border-radius: 15px;
}

.innerBar {
  background: $color-ui-9;
  width: 100%;
  height: 6px;
  border-radius: 15px;
}
