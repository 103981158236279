@import "styles/modules/constants";
@import "styles/mixins";

.inputToggleButton {
  right: 14px;
}

.dropdownWrapper {
  @include mt(3);
}

.filterDropdownMenu {
  min-width: 450px;
}

.inlinePlaceholder {
  @include flex(row, center, space-between);

  padding: 8px 16px 0;
  position: relative;
  min-height: 32px;
  transition: background-color 0.1s ease-in-out;
  border-bottom: 1px solid $color-ui-16;
  font-size: 14px;
}
