@import "styles/index";

.chartSidebarBox {
  @include flex($direction: column);

  // override inline styles by RND
  position: sticky !important;
  top: 70px !important;
  transform: none !important;
  max-height: calc(100vh - 80px) !important;
  margin: 0 0 0 32px;
  opacity: 1;
  transition:
    min-width $filtersAndPromotionsBarsTransitionTime $springLikeTransition,
    max-width $filtersAndPromotionsBarsTransitionTime $springLikeTransition,
    margin $filtersAndPromotionsBarsTransitionTime $springLikeTransition;
  border-radius: 14px;
  border: solid 1px $color-ui-17;
  background-color: $color-ui-13;
}

.header {
  margin: 18px 16px 14px;
  font-weight: bold;
  font-size: 19px;
  min-height: 55px;
}

.options {
  @include p(0, 3, 3);
  @include flex($align: flex-end, $justify: space-between);
}

.chartSidebarAbsoluteWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: opacity $filtersAndPromotionsBarsTransitionTime $springLikeTransition;
}

.chartSidebarAbsoluteWrapperClosed {
  width: 0%;
  opacity: 0;
}

.chartSidebarPreviewWrapper {
  padding: 24px 16px;
}

.previewHeader {
  font-size: 18px;
  font-weight: bold;
}

.previewInfoWrapper {
  @include flex($align: center);

  padding: 24px 0 0;
}

.tooltipIcon {
  width: 24px;
  height: 24px;
}

.previewText {
  font-size: 15px;
  margin-left: 16px;
  width: 90%;
}

.button {
  @include buttonPrimary;
  @include sidebarToggleButton;
  @include position(absolute, $top: 14px, $left: -31px);
  @include halfButton(left);

  transition: left $filtersAndPromotionsBarsTransitionTime ease-in-out;
  padding: 0;

  & svg {
    @include sidebarToggleButtonIcon;

    left: 6px;
  }
}

.buttonOpen {
  @include buttonSecondary;

  left: -32px;
}

.tabsWrapper {
  height: calc(100% - 143px);
}

.tabsList {
  display: flex;
  padding: 0 16px;
  margin-bottom: 8px;
  column-gap: 16px;
}

.tabsButton {
  cursor: pointer;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: 14px;
  color: $color-ui-2;
  position: relative;

  &.active {
    text-decoration: underline;
  }

  &:disabled {
    cursor: not-allowed;
    color: $color-ui-14;
  }
}

.tabsBadge {
  position: absolute;
  font-size: 10px;
  font-weight: 600;
  color: $color-ui-13;
  background-color: $color-ui-8;
  width: 16px;
  height: 16px;
  line-height: 18px;
  border-radius: 99px;
  transform: translate(1px, -5px);
}

.tabsContent {
  height: calc(100% - 25px);
  overflow: hidden;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}
