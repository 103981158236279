@import "styles/modules/constants";
@import "styles/mixins/index";
@import "components/atoms/atoms.module";

.toggleElement {
  @include toggleElement;
}

.button {
  @include resetButton;

  cursor: pointer;
  outline: none;
  width: 100%;
  padding: 4px 8px;
}

.label {
  display: inline-block;
  font-size: 14px;
  color: $color-ui-3;
}

.tooltip {
  @include defaultTooltip(attr(data-tooltip));

  &:hover::after {
    top: auto;
    bottom: calc(100% + 10px);
    width: auto;
    white-space: nowrap;
  }
}

.disabled .button {
  cursor: not-allowed;
  opacity: 0.7;
}
