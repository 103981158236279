@import "styles/mixins";

.summaryCard {
  padding: 0;
  flex-direction: column;
  position: relative;
  width: max-content;
  min-width: 100%;

  @media (min-width: $bp-lg) {
    margin: 0;
  }
}

.headingWrapper {
  @include flex($align: center);

  padding: 30px 40px 0;
}

.warningsWrapper {
  @include ml(3);

  max-width: 880px;
}

.warning {
  @include warning;

  &:not(:last-child) {
    @include mb(2);
  }
}

.summaryContent {
  @include flex($justify: flex-start);

  padding: 30px 40px;
  width: max-content;
}

.divider {
  display: flex;
  align-items: center;
  column-gap: 16px;
  position: absolute;
  left: 40px;
  bottom: 56px;
  width: calc(100% - 40px);
}

.dividerLabel {
  color: $color-ui-34;
  white-space: nowrap;
  font-size: 14px;
}

.dividerLine {
  height: 1px;
  width: 100%;
  background-color: $color-ui-33;
}
