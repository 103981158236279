@import "styles/mixins/index";

.tilesDropdownWrapper {
  @include flex;

  position: relative;
}

.tilesDropdownLabel {
  color: $color-ui-3;
  font-size: 15px;
  font-weight: normal;
}

.tilesDropdownHeader {
  color: $color-ui-3;
  font-size: 17px;
  font-weight: bold;
}

.chevronButton {
  @include flex($justify: center, $align: center);

  margin-left: 6px;
  background-color: $color-ui-9;
  border-radius: 0;
  min-width: 22px;
  min-height: 15px;
  max-width: 22px;
  max-height: 15px;
  cursor: pointer;
  border: 1px solid $color-ui-21;
  padding: 0;
}

.chevron {
  color: $color-ui-13;
}

.tilesDropdownBox {
  position: absolute;
  top: 20px;
  left: 0;
  display: grid;
  grid-template-rows: repeat(4, 0.9fr);
  min-width: 250px;
  background-color: $color-ui-13;
  padding: 16px;
  z-index: 5;
}

.selectTilesButton {
  @include defaultTooltip(attr(data-tooltip));
  @include resetButton;

  position: relative;
  cursor: pointer;
  width: fit-content;
  color: $color-ui-9;
  text-align: left;
  text-decoration: underline;

  &:not(:last-child) {
    @include mt(2);
  }

  &.disabled {
    cursor: not-allowed;
    color: $color-ui-31;
  }

  &:hover:not(.disabled) {
    text-decoration: none;
  }

  &:hover::after {
    top: 50%;
    left: calc(100% + 5px);
    transform: translateY(-50%);
  }
}

.iconTooltipWrapper {
  @include defaultTooltip(attr(data-tooltip));

  margin-left: 5px;
  cursor: pointer;
  position: relative;
  max-height: 1rem;

  &:hover::after {
    top: auto;
    bottom: calc(100% + 10px);
    width: 230px;
    z-index: 6;
    white-space: pre-wrap;
  }
}

.tooltipIcon {
  margin-top: -0.2rem;
}

.separator {
  display: block;
  margin: 10px 0 8px;
  width: 100%;
  height: 1px;
  background-color: $color-ui-17;
}
