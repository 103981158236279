@import "styles/index";
@import "styles/mixins";

.buttonLink {
  @include resetButton;
  @include my(1);

  display: block;
  border-radius: 0;
  min-width: auto;
  min-height: auto;
  color: $color-ui-9;
  white-space: nowrap;
  text-decoration: underline;
  position: relative;

  &:hover {
    text-decoration: none;
  }
}

.buttonTooltip {
  @include defaultTooltip(attr(data-tooltip));

  &:hover::after {
    white-space: pre-wrap;
    top: unset;
    bottom: 25px;
    transform: translateX(76%);
  }
}

.buttonLinkDisabled {
  opacity: 0.6;
  cursor: not-allowed;
  color: $color-ui-14;

  &:hover {
    text-decoration: underline;
  }
}
