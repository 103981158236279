@import "styles/index";
@import "styles/modules/constants";
@import "styles/mixins/index";
@import "components/atoms/atoms.module";

.wrapper {
  @include mb(3);
  @include flex($align: center, $justify: space-between, $wrap: wrap);

  gap: 6px;
}

.warnings {
  @include flex($wrap: wrap);
  @include mb(3);

  gap: 6px;
}

.warning {
  @include warning;
}

.controls {
  @include ml(auto);
  @include flex($direction: row, $wrap: wrap);

  gap: 6px;
}

.periodMenu {
  flex-shrink: 0;
}

.voivBackLinkText {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: $color-ui-3;
}

.voivBackLinkIcon {
  @include position($position: relative, $top: 6px);

  margin-right: 4px;
}

.list {
  @include flex;

  flex-shrink: 0;
  border-radius: 12px;
  margin-left: 8px;

  .element {
    border: 1px solid $color-ui-16;

    &:hover {
      background-color: $color-ui-33;
    }

    &:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    &:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    .button {
      @include defaultTooltip(attr(data-tooltip));

      border: none;
      cursor: pointer;
      position: relative;
      padding: 4px 8px;
      background: inherit;
      max-height: 26px;
      min-width: auto;
      min-height: auto;

      &:hover {
        background-color: $color-ui-33;

        &::after {
          top: 0;
          transform: translate(50%, calc(-100% - 10px));
          width: 100px;
        }
      }

      &[disabled] {
        @include inputDisabled;
      }
    }

    .firstButton {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    .lastButton {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }

  .active {
    background: $color-ui-7;
    border: 1px solid $color-ui-5;
  }
}
