@import "styles/mixins/index";

.input {
  @include globalInput($color-ui-9);
  @include inputPlaceholder($color-ui-3, 500);

  width: 100%;
  border-radius: 20px;
  font-size: 15px;
  color: $color-ui-2;
  border: 1px solid $color-ui-16;
  padding: 8px 22px;
  resize: none;

  &[disabled] {
    @include inputDisabled;
  }
}

.filled {
  border-color: $color-ui-5;
  background-color: $color-ui-7;
}

.labelText {
  font-weight: 600;
  padding-bottom: 8px;
}

.errorText {
  padding-left: 22px;
  padding-top: 4px;
  font-size: 14px;
  font-weight: 600;
  color: $color-ui-11;
}

.tooltip {
  @include defaultTooltip(attr(data-tooltip));

  position: relative;
  cursor: pointer;

  &:hover::after {
    width: 200px;
    padding: 6px 14px;
    transform: translateX(45%);
    top: auto;
    bottom: calc(100% + 10px);
  }
}
