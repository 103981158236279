@import "styles/index";

.buttonsWrapper {
  position: sticky;
  bottom: 0;
  background-color: transparentize($color-ui-13, 0.1);
  z-index: 2;
  padding: 16px 32px;
  width: 100%;
}

.submitButton {
  @include buttonPrimary;
  @include mb(2);
  @include flex(row, center, center);

  width: 100%;

  &:disabled {
    opacity: 1;
    background-color: rgba($color-ui-9, 0.3);

    &:hover {
      background-color: rgba($color-ui-9, 0.3);
    }
  }
}

.withFakeCategoryTooltip:disabled {
  @include defaultTooltip(attr(data-fake-category-tooltip));
}

.submitButtonText {
  @include buttonTextPrimary;
}

.resetButton {
  @include resetButton;
  @include flex($justify: center, $align: center);

  width: 100%;
}

.resetButtonText {
  @include buttonTextSecondary;

  position: relative;
  font-size: 14px;
  transition: color 0.1s ease-in-out;

  &:hover,
  &:hover svg {
    color: $color-ui-8;
  }

  &:active,
  &:active svg {
    color: $color-ui-9;
  }
}

.resetButtonIcon {
  @include position($position: absolute, $left: -20px);

  color: $color-ui-9;
  transition: color 0.1s ease-in-out;
}

.heading {
  margin-top: 32px;
  color: $color-ui-2;
  font-size: 18px;
}

// CHOICE GROUP STYLES
@mixin choiceGroupBase {
  @include mb(2);
}

@mixin choiceGroupHeader {
  font-size: 15px;
  font-weight: 600;
  color: $color-ui-1;
}

@mixin choiceGroupCheckbox {
  @include mt(2);
}

@mixin choiceGroupCheckboxLabel {
  font-size: 14px;
  color: $color-ui-3;
}

@mixin choiceGroupCheckboxLabelChecked {
  color: $color-ui-1;
  padding-top: 1px;
}

.filterDateDropdown {
  @include my(3);

  width: 100%;
}
