@import "styles/mixins/index";
@import "styles/modules/constants";

.popupWindow {
  width: 100%;
  max-width: 500px;
  padding: 25px;
  border-radius: 14px;
  box-shadow: $defaultBoxShadow;
  border: 1px solid $color-ui-16;
  background-color: $color-ui-7;
}

.popupContent {
  @include flex($direction: column, $align: center);
}

.button {
  @include buttonPrimary;
}

.centeredText {
  text-align: center;
}

.mainText {
  margin-bottom: 10px;
}
