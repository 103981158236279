@import "styles/index";

.month-range-picker-wrapper {
  height: 35px;
}

.month-range-picker-popper {
  width: 320px;
  margin-top: 1.25em;
}

.month-range-picker-input {
  width: 150px;
  margin: 0 5px;
  border-radius: 20px;
  font-size: 15px;
  color: $color-ui-2;
  border: 1px solid $color-ui-16;
  padding: 8px 22px;
}

.month-range-picker {
  .react-datepicker {
    &__month-wrapper {
      @include flex($align: center);
    }

    &__month-text {
      padding: 12px 22px;
      text-align: center;
      width: 31%;
      margin: 1%;
      cursor: pointer;
      border: 1px solid $color-ui-16;
      border-radius: 5px;
      background-color: $color-ui-13;
      transition: all 0.2s ease-in;
      color: $color-ui-2;

      &:hover {
        background-color: $color-ui-33;
      }
    }

    &__month {
      width: 100%;

      &--selected,
      &--in-range {
        background-color: $color-ui-33;
      }

      &--disabled {
        opacity: 0.4;

        &:hover {
          background-color: $color-ui-13;
          cursor: not-allowed;
        }
      }
    }

    &__navigation {
      border: 0;
      padding: 5px 0;
      width: 25px;
      cursor: pointer;
      background-color: $color-ui-9;
      border-radius: 25px;
      color: $color-ui-13;
      font-weight: bold;
      position: absolute;
      top: -5px;
      outline: 0;

      &:hover {
        background-color: $color-ui-8;
      }

      &--previous {
        left: 110px;
      }

      &--next {
        right: 110px;
      }
    }

    &-year-header {
      color: $color-ui-2;
      margin-bottom: 10px;
      font-size: 18px;
      text-align: center;
    }
  }
}
