@import "styles/index";

.base {
  @include flex($direction: column);

  position: relative;
  border-radius: 14px;
  box-shadow: 0 0 8px 0 rgba(238, 239, 242, 0.63);
  border: solid 1px $color-ui-17;
  background-color: $color-ui-13;
}

.withHeader {
  padding-top: 16px;
}

.loader {
  @include loaderBlock;
}

.loaderOpaque {
  background-color: $color-ui-13;
}
