@import "styles/mixins";
@import "styles/modules/constants";

.table {
  font-size: 14px;
  height: 1px; // https://limebrains.com/blog/2021-03-02T13:00-heigh-100-inside-table-td/
}

.tableHeaderRow {
  background-color: $color-ui-7;

  th {
    border: 0;
    max-width: 150px;
    border-radius: 0 !important; // important is needed as there is an issue in which second classname doesnt overwrite first one

    &:first-child {
      border-right: 1px solid $color-ui-17 !important; // overwrite tableCell styles
    }
  }
}

.tableHeader {
  padding: 12px 8px !important; // important is needed as there is an issue in which second classname doesnt overwrite first one
}

.tableCell {
  vertical-align: middle;
  padding: 0 !important; // important is needed as there is an issue in which second classname doesnt overwrite first one
  border: 0;
}

.noData {
  padding: 10px 0 0 16px;
}

.stickyFirstColumn {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: inherit;
  border-right: 1px solid $color-ui-17 !important; // overwrite tableCell styles
}

.columnDividerOnRight {
  border-right: 1px solid $color-ui-17 !important; // overwrite tableCell styles
}

.stickyFirstColumnHeader {
  z-index: 4;
}

.stickyHeaderRow {
  position: sticky !important;
  top: 0;
  z-index: 3;
  background-color: inherit;
}

.noLeftPadding {
  padding-left: 0 !important;
}
