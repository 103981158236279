@import "styles/mixins/index";
@import "styles/modules/constants";

.popupContainer {
  @include position(fixed, $top: 0, $left: 0);
  @include flex($align: center, $justify: center);

  height: 100vh;
  width: 100vw;
  background-color: $color-ui-23;
  z-index: 20;
}

.button {
  @include buttonPrimary;
}

.centeredText {
  text-align: center;
}

.mainText {
  margin-bottom: 10px;
}

.secondaryText {
  margin-bottom: 18px;
}
