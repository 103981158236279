@import "styles/mixins/index";

.loadingInfo {
  @include flex($direction: column, $justify: center);
  @include p(3);

  position: absolute;
  width: 100%;
  z-index: 4;
  border-radius: 14px;
  background-color: $color-ui-12;
}

.paragraph {
  @include mb(1);

  font-size: 1.1rem;
  font-weight: bold;
}
