@import "styles/index";

.base {
  @include flex(column);

  padding: 24px;
  width: 436px;
}

.modalText {
  font-size: 14px;
  font-weight: 600;
  flex: 2;
  white-space: break-spaces;
  line-height: 1.43;
}

.buttonContainer {
  @include flex($justify: space-around, $align: center);

  margin-top: 16px;
  flex: 1;
}

@mixin modalButton {
  height: 32px;
}

.confirmButton,
.cancelButton {
  @include modalButton;
  @include flex(row, center, center);

  color: $color-ui-6;
}

.confirmButton {
  @include buttonPrimary;
}

.cancelButton {
  @include buttonSecondary;
}

.buttonText {
  @include buttonTextPrimary;

  display: flex;
  margin-right: 8px;
}

.popup {
  @include position(absolute 0, 0, 0, 0);

  background-color: $color-ui-30;
}

.card {
  margin: 20px;
  box-shadow:
    0 6px 30px 5px rgba(56, 69, 93, 0.12),
    0 16px 24px 2px rgba(56, 69, 93, 0.14),
    0 0 0 1px rgba(146, 155, 170, 0.12);
}

.cardContainer {
  @include position($position: absolute, $left: 20px);
}

.svg {
  display: inline-flex;
}
