@import "styles/index";

.legend {
  margin-top: 4px;
  line-height: 32px;
}

.flex {
  @include flex;

  padding-left: 40px;
}

.activeTab {
  font-weight: 600;
  line-height: 30px;

  &:not(:first-child) {
    margin-top: 4px;
  }
}

.button {
  @include resetButton;

  min-height: initial;
  cursor: pointer;
  text-align: left;
  padding: 1px 4px;

  &.disabled {
    opacity: 0.4;
  }
}

.buttonHovered {
  background-color: $color-ui-26;
  border-radius: 5px;
}
