$time: 0.25s;
$opacityTransition: ease-in-out;
$strokeWidthTransition: cubic-bezier(0.42, -0.7, 0.58, 1.7);

.linePath {
  transition: stroke-width $time $strokeWidthTransition, opacity $time $opacityTransition;
}

.groupedStackChartRect {
  transition: opacity $time $opacityTransition;
}

.tileBaseGroupedStackChart {
  transform: translate(-50%, -20%);
  background-color: transparent !important; // this one overrides inline styles applied by component props
  padding: 0;
  font-size: 13px;
}
