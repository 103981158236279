@import "styles/modules/constants";

@mixin resetButton {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  outline: none;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
}

@mixin inputActive($activeColor) {
  outline: none;
}

@mixin inputFocus($activeColor) {
  outline: none;
}

@mixin globalInput($activeColor) {
  outline-offset: -2px;

  &:active {
    @include inputActive($activeColor);
  }

  &:focus {
    @include inputFocus($activeColor);
  }

  &[disabled] {
    @include inputDisabled;
  }
}

@mixin tooltipShadow {
  box-shadow:
    0 6px 30px 5px rgba(56, 69, 93, 0.12),
    0 16px 24px 2px rgba(56, 69, 93, 0.14),
    0 0 0 1px rgba(146, 155, 170, 0.12);
}

@mixin buttonBasics($activeColor) {
  border: 0;
  background-color: $activeColor;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: darken($activeColor, 15%);
  }

  &:active {
    background-color: darken($activeColor, 5%);
  }

  &:hover[disabled],
  &:focus[disabled],
  &:active[disabled] {
    background-color: $activeColor;
  }
}

@mixin halfButton($direction) {
  border-radius: 0;
  border-top-#{$direction}-radius: 16px;
  border-bottom-#{$direction}-radius: 16px;
}

@mixin buttonPrimary {
  @include buttonBasics($color-ui-9);
}

@mixin buttonSecondary {
  @include buttonBasics($color-ui-4);
}

@mixin sidebarToggleButton {
  display: inline-flex;
  align-items: center;
  width: 32px;
  min-width: 0;
}

@mixin sidebarToggleButtonIcon {
  @include buttonTextPrimary;

  position: absolute;
  width: 26px;
  height: 26px;
  top: 2px;
}

@mixin buttonTextPrimary {
  color: $color-ui-13;
  font-weight: 600;
}

@mixin buttonTextSecondary {
  @include buttonTextPrimary;

  color: $color-ui-3;
}

@mixin inputDisabled {
  opacity: 0.7;
  cursor: not-allowed;
  outline: none;
}

@mixin filterDropdownInput {
  font-weight: 600;
  padding-right: 32px;
  text-overflow: ellipsis;
}

@mixin inputPlaceholder($color, $fontWeight) {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color;
    font-weight: $fontWeight;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
    font-weight: $fontWeight;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $color;
    font-weight: $fontWeight;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
    font-weight: $fontWeight;
  }
}

@mixin alertButton {
  @include resetButton;
  @include globalInput($color-ui-1);

  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;
  padding-top: 4px;
}

@mixin criticalAlert {
  background-color: $color-ui-12;
}

@mixin criticalAlertText {
  color: $color-ui-11;
}

@mixin criticalAlertButton {
  color: $color-ui-11;
}

@mixin successAlert {
  background-color: $color-ui-19;
}

@mixin successAlertText {
  color: $color-ui-8;
}

@mixin successAlertButton {
  color: $color-ui-8;
}

@mixin loginInput {
  padding: 12px 22px;
  font-size: 15px;
}

@mixin resetTextArea {
  width: 100%;
  height: 100%;
  border: none;
  padding: 15px 24px;
  font-size: 15px;
  color: $color-ui-2;
  outline: none;
  resize: none;
  line-height: 1.33;
}

@mixin loaderBlock {
  @include position(absolute, 0, 0, 0, 0);
  @include flex($align: center, $justify: center);

  border-radius: 14px;
  background-color: $color-ui-30;
  width: 100%;
  height: 100%;
  z-index: 4;
}

@mixin closeIconPrimary {
  color: $color-ui-4;
}

@mixin defaultTooltip($content) {
  &:hover {
    &::after {
      @include position(absolute, $top: -40px, $right: 50%);

      content: $content;
      transform: translateX(50%);
      width: 200px;
      background-color: $color-ui-9;
      color: $color-ui-13;
      padding: 5px 14px;
      border-radius: 15px;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      z-index: 5;
    }
  }
}

@mixin defaultBadge($content) {
  &::before {
    @include position(absolute, $top: 0, $right: 0);

    background-color: $color-ui-18;
    border-radius: 2px;
    color: $color-ui-13;
    content: $content;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 5px 3px;
    text-transform: uppercase;
  }
}

@mixin scrollbarThumb {
  border-radius: 8px;
  background-color: $customScrollbar;
}

@mixin warning {
  @include p(2, 3);

  display: block;
  background-color: $color-ui-11;
  color: $color-ui-13;
  border-radius: 10px;
  line-height: 1.2;
}

@mixin separator {
  content: "";
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  width: 1px;
  height: 18px;
  background-color: $color-ui-5;
}
