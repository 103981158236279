@font-face {
  font-family: "TTCommons";
  src:
    url("../../assets/fonts/tt_commons_regular-webfont.woff") format("woff"),
    url("../../assets/fonts/tt_commons_regular-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "TTCommons";
  src:
    url("../../assets/fonts/tt_commons_medium_italic-webfont.woff") format("woff"),
    url("../../assets/fonts/tt_commons_medium_italic-webfont.woff2")
    format("woff2");
  font-style: italic;
}

@font-face {
  font-family: "TTCommons";
  src:
    url("../../assets/fonts/tt_commons_demibold-webfont.woff") format("woff"),
    url("../../assets/fonts/tt_commons_demibold-webfont.woff2") format("woff2");
  font-weight: 600 900;
}

@font-face {
  font-family: "TTCommons";
  src:
    url("../../assets/fonts/tt_commons_demibold_italic-webfont.woff")
    format("woff"),
    url("../../assets/fonts/tt_commons_demibold_italic-webfont.woff2")
    format("woff2");
  font-weight: 600 900;
  font-style: italic;
}
