@import "styles/index";
@import "styles/mixins/index";

.listWrapper {
  max-height: 266px;
  height: fit-content;
  width: 100%;
  box-sizing: content-box;
  overflow: hidden !important; // important needed due to react-custom-scrollbar props with default styles being passed, otherwise it doesnt work properly according to documentation
}
