@import "styles/index";

.options {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.checkbox {
  margin: 0 8px 0 12px;
}

.label {
  color: $color-ui-3;
  font-size: 15px;
  font-weight: normal;
}

.wrapper {
  width: 100%;
  overflow-x: auto;
}

.table {
  user-select: none;
  width: 100%;
  font-size: 14px;
  color: $color-ui-2;
  text-align: center;
  margin-bottom: 12px;

  thead th {
    padding: 4px;
    font-weight: 600;
    vertical-align: middle;
  }

  tbody td {
    border-top: 1px solid $color-ui-17;
    min-width: 100px;
    padding: 4px;

    &:first-child {
      font-weight: 600;
      text-align: left;
      vertical-align: middle;
      white-space: nowrap;
    }
  }

  .sticky {
    position: sticky;
    left: 0;
    background-color: $color-ui-13;
  }

  .value {
    margin-bottom: 2px;
  }

  .positive {
    color: $color-ui-9;
  }

  .negative {
    color: $color-ui-18;
  }
}
