@import "styles/index";

.listTitle {
  @include p(2, 3);

  font-size: 16px;
  font-weight: 600;
}

.loaderWrapper {
  @include flex($justify: center);

  margin-top: 32px;
}

.noInsightsWrapper {
  padding: 16px;
}

.noInsightsText {
  padding: 6px 0;
}

.headerText {
  text-align: left;
}

.sectionContent {
  padding: 0 24px 20px !important; // override component styles
}
