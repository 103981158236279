@import "styles/modules/constants";
@import "styles/mixins/index";

$border: 1px solid $color-ui-16;

.base {
  position: relative;
  min-height: 32px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  transition: background-color 0.1s ease-in-out;
  border-bottom: $border;
  width: 100%;

  &:hover {
    background-color: darken($color-ui-7, 10%);
  }
}

.active {
  border-top: $border;
  border-bottom: $border;
  background-color: $color-ui-7;
}

.iconContainer {
  position: absolute;
  left: 25px;
}

.filterElementText {
  white-space: normal;
}

.greyText {
  color: $color-ui-37;
}

.tooltipWrapper {
  @include defaultTooltip(attr(data-tooltip));
  @include ml(5);

  cursor: pointer;
  position: relative;

  &:hover::after {
    top: 50%;
    right: calc(100% + 5px);
    bottom: unset;
    left: unset;
    transform: translateY(-50%);
  }
}
