@import "styles/modules/constants";

.spinnerLoaderBackground {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 8px;
  border: 3px solid $color-ui-27;
  border-radius: 50%;
}

.spinnerLoaderWrapper .spinner {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 8px;
  border: 3px solid $color-ui-9;
  border-radius: 50%;
  animation: spinner-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $color-ui-9 transparent transparent transparent;
  z-index: 2;
}

.spinnerLoaderWrapper {
  display: inline-block;
  position: relative;
  width: 45px;
  height: 45px;

  &.small {
    width: 16px;
    height: 16px;
    transform: translate(-6px, -6px);

    & .spinner {
      width: 16px;
      height: 16px;
    }

    & .spinnerLoaderBackground {
      width: 16px;
      height: 16px;
    }
  }

  &.medium {
    width: 22px;
    height: 22px;

    & .spinner {
      width: 22px;
      height: 22px;
    }

    & .spinnerLoaderBackground {
      width: 22px;
      height: 22px;
    }
  }
}

.spinnerLoaderWrapper .spinner:nth-child(1) {
  animation-delay: -0.45s;
}

.spinnerLoaderWrapper .spinner:nth-child(2) {
  animation-delay: -0.3s;
}

.spinnerLoaderWrapper .spinner:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes spinner-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
