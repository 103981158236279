@import "styles/mixins/index";

.headerWrapper {
  @include flex($justify: space-between, $align: flex-start);
}

.headerText {
  font-weight: 600;
  display: block;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
}

.sortHeaderButton {
  @include flex($justify: center, $align: center);

  margin-left: 6px;
  background-color: $color-ui-13;
  border-radius: 0;
  min-width: 22px;
  min-height: 18px;
  max-width: 22px;
  max-height: 18px;
  cursor: pointer;
  border: 1px solid $color-ui-21;
  padding: 0;
}

.sortHeaderButtonActive {
  background-color: $color-ui-9;
}

.iconWrapper {
  @include position(absolute, -1px, -20px);
}

.chevron {
  color: $color-ui-1;
}

.chevronActive {
  color: $color-ui-13;
}

.sidebarSortBox {
  z-index: 10;
}

.sidebarSortBoxWrapper {
  min-width: 260px;
  max-width: 260px;
  background-color: $color-ui-13;
  padding: 16px;
  z-index: 5;
}

.wrapperFromLeft {
  left: 16px;
  right: unset;
}

.sortingTitle {
  font-size: 16px;
  color: $color-ui-2;
  font-weight: 600;
  margin-bottom: 10px;
}

.sortingRadioButton:not(:last-child) {
  margin-bottom: 8px;
}

.sortingCheckboxButton {
  margin: 8px 0;
  font-size: 16px;
  color: $color-ui-2;
  max-width: 75%;
}

.acceptButton {
  @include buttonPrimary;
  @include buttonTextPrimary;

  width: 60px;
  margin-top: 16px;
  align-self: end;
}

.tooltipIcon {
  &:hover::after {
    top: calc(100% + 10px);
    bottom: auto;
    transform: none;
    right: 0;
  }
}
