@import "styles/index";

$transitionTime: 0.2s;

.checkbox {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: solid 1px $color-ui-16;
  cursor: pointer;
  transition: border-color $transitionTime ease-in-out;
  position: relative;
  margin-right: 12px;

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & input[type="checkbox"] {
    visibility: hidden;

    & + div {
      @include position(absolute, $top: -1px, $left: 1px);

      opacity: 0;
      color: $color-ui-9;
      cursor: pointer;
      transition: opacity $transitionTime ease-in-out;
    }

    &:checked + div {
      opacity: 1;
    }

    &[disabled] + div {
      cursor: not-allowed;
    }
  }
}

.base {
  @include flex($align: center);

  position: relative;
  cursor: pointer;

  .checkbox.checked {
    border-color: $color-ui-9;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &:hover .checkbox:not(.disabled),
  &:focus .checkbox:not(.disabled) {
    border-color: darken($color-ui-16, 15%);
  }

  &:active .checkbox:not(.disabled) {
    border-color: darken($color-ui-16, 5%);
  }

  &:hover .checkbox.checked:not(.disabled),
  &:focus .checkbox.checked:not(.disabled) {
    border-color: darken($color-ui-9, 15%);
  }

  &:active .checkbox.checked:not(.disabled) {
    border-color: darken($color-ui-9, 5%);
  }
}

.tooltip {
  @include defaultTooltip(attr(data-tooltip));

  &:hover::after {
    top: unset;
    bottom: 20px;
    height: auto;
    width: 250px;
    white-space: pre-wrap;
  }
}

.tick {
  width: 12px;
  height: 12px;
}

.checkBoxLabelSmall {
  padding-top: 1px;
  font-size: 14px;
  color: $color-ui-3;

  &.checked {
    color: $color-ui-2;
  }
}

.checkBoxLabelDisabled {
  opacity: 0.4;
}

.boldText {
  display: block;

  &::after {
    content: attr(data-text);
    display: block;
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}

.iconTooltipWrapper {
  @include defaultTooltip(attr(data-tooltip));

  margin-left: 2px;
  cursor: pointer;
  position: relative;
  max-height: 1rem;

  &:hover::after {
    top: auto;
    bottom: calc(100% + 10px);
    width: auto;
    min-width: 200px;
    z-index: 6;
  }
}

.tooltipIcon {
  margin-top: -0.2rem;
}
