@import "styles/index";

@mixin toggleElement {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 28px;
  min-width: 80px; // override fix from `flex.scss` file
  border: 1px solid $color-ui-16;
  border-left: 0;

  &.active {
    border: 1px solid $color-ui-5;
    background-color: $color-ui-7;
    font-weight: 600;
    color: $color-ui-2;

    &:first-child {
      border-left: 1px solid $color-ui-5;
    }

    &:hover,
    &:focus {
      background-color: $color-ui-33;
    }

    &:active {
      background-color: $color-ui-7;
    }
  }

  &:not(:first-child) {
    margin-left: -1px;
  }

  &:first-child {
    border-top-left-radius: $TOGGLE_BORDER_RADIUS;
    border-bottom-left-radius: $TOGGLE_BORDER_RADIUS;
    border-left: 1px solid $color-ui-16;
  }

  &:last-child {
    border-top-right-radius: $TOGGLE_BORDER_RADIUS;
    border-bottom-right-radius: $TOGGLE_BORDER_RADIUS;
  }

  &:hover:not(.disabled),
  &:focus:not(.disabled) {
    background-color: $color-ui-33;
  }

  &:active:not(.disabled) {
    background-color: $color-ui-7;
  }
}
