@import "styles/index";

.slider {
  // padding & negative margin is a hack to prevent cropping disabled tab tooltip
  padding-top: 20px;
  margin: -20px 0 0 -32px;
  width: 100vw;

  @media (min-width: $bp-lg) {
    margin: -20px 0 0;
    width: 100%;
  }
}

.summaryCard {
  @include inline-flex;

  margin: 0 2rem;
  position: relative;
  min-width: 100%;
  overflow: auto;

  @media (min-width: $bp-lg) {
    margin: 0;
  }
}

.content {
  @include inline-flex;

  @media (min-width: $bp-lg) {
    @include flex($justify: space-between);
  }
}

.loader {
  @include loaderBlock;
}
