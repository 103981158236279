@import "styles/index";

.hidePlanogramBase {
  @include flex;

  height: 28px;
  margin-left: 10px;
}

.hidePlanogramCheckbox {
  margin-right: 5px;
}

.hidePlanogramLabel {
  color: $color-ui-3;
  font-size: 15px;
  font-weight: normal;
}
